import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { CommonService } from '@kayne/ka-ui-lib-ng7';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-read-footer',
  templateUrl: './read-footer.component.html',
  styleUrls: [
    './read-footer.component.css'
  ]
})
export class ReadFooterComponent implements OnInit, OnDestroy {

  @Input() emailUs: string;
  @Input() emailUsCc: string;

  uiVersion: string = null;
  serverVersion: string = null;

  public currentYear: number = (new Date()).getFullYear();

  get emailSupport() {
    const to = (this.emailUs || '').split(';');
    const cc = (this.emailUsCc || '').split(';');

    return [...to, ...cc].join(', ');
  }

  constructor(
    private commonService: CommonService
  ) {
    this.commonService.onAppInformationChanged.subscribe(() => this.getAppInformation());
  }

  ngOnInit() {
    this.getAppInformation();
  }

  private getAppInformation() {
    const appInformation = this.commonService.getAppInformation();
    this.uiVersion = appInformation.uiVersion;
    this.serverVersion = appInformation.serverVersion;
  }

  ngOnDestroy() {
    this.commonService.onAppInformationChanged = new Subject();
  }
}
