import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilityService, CommonService, AppInfoType } from '@kayne/ka-ui-lib-ng7';

@Component({
  selector: 'app-read-server-down',
  templateUrl: './read-server-down.component.html',
  styleUrls: ['./read-server-down.component.css']
})
export class ReadServerDownComponent implements OnInit {
  constructor(private router: Router, private common: CommonService, private utility: UtilityService) { }

  ngOnInit() {
    this.common.getAppVersion()
      .subscribe(
        (info: AppInfoType) => {
          const { build } = info;

          if (build) {
            this.common.updateAppInformation({ serverVersion: build.version });
            this.router.navigate(['/']);
          }
        },
        () => { },
        () => this.utility.toggleLoading(false)
      );
  }
}
