import { ComponentModule } from './../../components/component.module';
import { MainFilterService } from './services/main-filter.service';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MainFilterComponent } from './main-filter.component';
import { MainFilterGeographyComponent } from './components/main-filter-geography/main-filter-geography.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { KarCoreComponentModule } from '@kayne/ka-ui-lib-ng7';
import { MainFilterCustomComponent } from './components/main-filter-custom/main-filter-custom.component';

@NgModule({
    declarations: [
        MainFilterComponent,
        MainFilterGeographyComponent,
        MainFilterCustomComponent
    ],
    exports: [
        MainFilterComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgSelectModule,
        KarCoreComponentModule,
        ComponentModule
    ],
    providers: [
        MainFilterService
    ],
    entryComponents: []
})
export class MainFilterModule { }
