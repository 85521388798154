import { ComponentModule } from '../../components/component.module';
import { NgModule } from '@angular/core';
import { Route } from '@angular/router';
import { KarEj2TreeGridModule } from '../kar-ej2-treegrid/kar-ej2-treegrid.module';
import { KarSharingModule } from 'src/app/shared/sharing/kar-sharing.module';
import { CommonModule } from '@angular/common';
import { KarAccountService } from './services/kar-account.service';
import { KarCoreComponentModule } from '@kayne/ka-ui-lib-ng7';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { KarAccountComponent } from './kar-account.component';

const routes: Route[] = [
    {
        path: '',
        component: KarAccountComponent
    }
];

@NgModule({
    declarations: [
        KarAccountComponent
    ],
    exports: [
        KarAccountComponent
    ],
    imports: [
        CommonModule,
        NgSelectModule,
        FormsModule,
        KarEj2TreeGridModule,
        KarSharingModule.forRoot(),
        KarCoreComponentModule,
    ],
    providers: [KarAccountService],
    entryComponents: [KarAccountComponent]
})
export class KarAccountModule { }
