import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { EM_DASH, NumeralService, ParseNumericParams } from '@kayne/ka-ui-lib-ng7';

interface ParseNumericOption {
    isPercentage?: boolean;
    isRatio?: boolean;
}

export const NUMERAL_PERCENTAGE_FORMAT = '0,0.00';
export const NUMERAL_RATIO_FORMAT = '0,0.00';
export const NUMERAL_CURRENCY_FORMAT = '0,0';

export const NUMERAL_PERCENTAGE_DECIMAL_PLACES = 2;
export const NUMERAL_RATIO_DECIMAL_PLACES = 2;
export const NUMERAL_CURRENCY_DECIMAL_PLACES = 0;

enum DataFormatTypeEnum {
    RATIO = 'Decimal',
    PERCENTAGE = 'Percentage',
    CURRENCY = 'Currency'
}

@Injectable({
    providedIn: 'root',
})
export class ReadNumeralService {
    constructor(private readonly numeralService: NumeralService) { }

    parsePercentage(value: number, options?: ParseNumericParams) {
        const parseOptions = { empty: EM_DASH, blank: EM_DASH, roundDecimal: 2, ...options };
        if (!parseOptions.format) {
            parseOptions.format = '0,000.[00]';
        }

        return this.numeralService.parsePercentage(
            value,
            parseOptions
        )
    }

    parseCurrency(value: number, options?: ParseNumericParams) {
        const parseOptions = { empty: EM_DASH, blank: EM_DASH, roundDecimal: 0, ...options };
        if (!parseOptions.format) {
            parseOptions.format = '0,000';
        }

        return this.numeralService.parseNumeric(value,
            parseOptions,
            (displayValue) => {
                if (value === 0) return EM_DASH;
                return value < 0 ? `(${displayValue})` : `${displayValue}`;
            }
        )
    }

    parseInteger(value: number, options?: ParseNumericParams) {
        const parseOptions = { empty: EM_DASH, blank: EM_DASH, roundDecimal: 2, ...options };
        if (!parseOptions.format) {
            parseOptions.format = '0,000.[00]';
        }

        return this.numeralService.parseNumeric(
            value,
            parseOptions,
            (displayValue) => {
                if (value === 0) return EM_DASH;
                return value < 0 ? `(${displayValue})` : `${displayValue}`;
            }
        );
    }

    convertFormatTypeToFlags(formatType: string): any {
        if (!formatType) {
            return { isRatio: true, isPercentage: false };
        }
        return {
            isRatio: formatType === DataFormatTypeEnum.RATIO,
            isPercentage: formatType === DataFormatTypeEnum.PERCENTAGE
        };
    }

    formatAmountCell(dataValue: number, dataType: string, options?: ParseNumericParams) {
        switch (dataType) {
            case 'PERCENTAGE':
                return this.parsePercentage(dataValue, options);
            case 'CURRENCY':
                return this.parseCurrency(dataValue, options);
            default:
                return this.parseInteger(dataValue, options);
        }
    }
}