import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService, UserProfileService, CommonService, ModalMode, UtilityService } from '@kayne/ka-ui-lib-ng7';
import { Title } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { environment } from '../environments/environment';
import { Router, NavigationStart, Event, RouterEvent, RouteConfigLoadStart, RouteConfigLoadEnd, NavigationEnd, ActivatedRoute } from '@angular/router';
import packageJson from '../../package.json';
import { ReadLeftNavbarComponent } from './components/read-left-navbar/read-left-navbar.component';
import { SettingModalComponent } from './components/setting-modal/setting-modal.component';
import { SidebarItemModel } from './models/common.model';
import { KarAccountComponent } from './modules/kar-account/kar-account.component';
import { Location } from '@angular/common';
import { UserPermissionService } from './services/user-permission.service';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styles: [`
        .wrap-contain {
            margin-left: 300px;
            padding: 8px;
            transition: .5s all;
            margin-top: 80px;
            height: calc(100vh - 130px);
            padding-bottom: 0.6rem;
            position: relative;
            padding-top: 63px;
            z-index: 1;
            width: calc(100% - 300px);
        }
        .wrap-contain.collapsed-layout {
            margin-left: 72px;
            transition: .5s all;
            width: calc(100% - 73px);
            position: relative;
        }
        .wrap-contain.no-global-filter-layout {
            height: calc(100vh - 136px) !important;
            margin-top: 80px;
            padding-top: 10px;
        }
        .wrap-contain .main-contain {
            padding-left: 8px;
            height: 100%;
        }
        .wrap-contain.hidden-navbar {
            width: 100%;
            margin: 0;
            padding-left: 0;
            height: 100vh !important;
        }
    `]
})
export class AppComponent implements OnInit {
    @ViewChild('leftNav') leftNav: ReadLeftNavbarComponent;
    private modalClass = `reference-data-detail-modal`;
    modalRef: any;
    loggedInUserEmail: string = null;
    loggedInUserName: string = null;
    appVersion = packageJson.version;
    emailUs = environment.emailUs;
    emailUsCc = environment.emailUsCc;
    headerTitle = environment.appTitle;
    startRoutes: SidebarItemModel[] = [
        {
            name: 'Home',
            url: '/home',
            activeUrls: ['/', '/home'],
            icon: 'home',
        },
        {
            name: 'Executive',
            url: '/executive',
            activeUrls: ['/executive'],
            icon: 'users',
        },
        {
            name: 'Platforms',
            url: '/platforms',
            activeUrls: ['/platforms'],
            isOpenChild: false,
            icon: 'platform',
            child: [
                {
                    name: 'Opportunistic',
                    url: '/opportunistic',
                    activeUrls: ['/opportunistic'],
                },
                {
                    name: 'Core',
                    url: '/core',
                    activeUrls: ['/core'],
                },
                {
                    name: 'Debt',
                    url: '/debt',
                    activeUrls: ['/debt'],
                },
                {
                    name: 'Impact',
                    url: '/impact',
                    activeUrls: ['/impact'],
                }
            ]
        },
        {
            name: 'Teams',
            url: '/teams',
            activeUrls: ['/teams'],
            isOpenChild: false,
            icon: 'teams',
            child: [
                {
                    name: 'Medical Office',
                    url: '/medical-office',
                    activeUrls: ['/medical-office'],
                },
                {
                    name: 'Seniors Housing',
                    url: '/seniors-housing',
                    activeUrls: ['/seniors-housing'],
                },
                {
                    name: 'Student/Multi',
                    url: '/student-multi',
                    activeUrls: ['/student-multi'],
                },
                {
                    name: 'Investor Relations',
                    url: '/investor-relations',
                    activeUrls: ['/investor-relations'],
                },
                {
                    name: 'FP&A',
                    url: '/FP&A',
                    activeUrls: ['/FP&A'],
                },
                {
                    name: 'Accounting',
                    url: '/accounting',
                    activeUrls: ['/accounting/upload', '/accounting/review', '/accounting/report', '/accounting/task'],
                    // isOpenChild: false,
                    // child: [
                    //     {
                    //         name: 'Upload',
                    //         url: '/accounting/upload',
                    //         activeUrls: ['/upload'],
                    //     },
                    //     {
                    //         name: 'Review',
                    //         url: '/accounting/review',
                    //         activeUrls: ['/accounting/review'],
                    //     },
                    //     {
                    //         name: 'Report',
                    //         url: '/accounting/report',
                    //         activeUrls: ['/accounting/report'],
                    //     },
                    //     {
                    //         name: 'Task',
                    //         url: '/accounting/task',
                    //         activeUrls: ['/accounting/task'],
                    //     },
                    // ],
                },
                // {
                //     name: 'Accounting - Task',
                //     url: '/accounting-task',
                //     activeUrls: ['/accounting-task'],
                // },
                {
                    name: 'Legal',
                    url: '/legal',
                    activeUrls: ['/legal', '/legal/org'],
                    isOpenChild: false,
                    child: [
                        {
                            name: 'Org Charts',
                            url: '/legal/org',
                            activeUrls: ['/legal/org'],
                        }
                    ],
                },
                {
                    name: 'HR',
                    url: '/hr',
                    activeUrls: ['/hr'],
                }
            ]
        },
        {
            name: 'Valuations',
            url: '/valuations',
            activeUrls: ['/valuations'],
            icon: 'valuation',
        },
        // {
        //     name: 'Covenant',
        //     url: '/covenant',
        //     activeUrls: ['/covenant'],
        //     icon: 'covenant',
        // },
        {
            name: 'Performance',
            url: '/performance',
            activeUrls: ['/performance'],
            icon: 'performance',
            isOpenChild: false,
            child: [
                {
                    name: 'Financials',
                    url: '/financials',
                    activeUrls: ['/financials'],
                },
                {
                    name: 'Covenants',
                    url: '/covenants',
                    activeUrls: ['/covenants'],
                    // enableUrl: true,
                    // isOpenChild: false,
                    // child: [
                    //     {
                    //         name: 'Upload',
                    //         url: '/covenant-upload',
                    //         activeUrls: ['/covenant-upload'],
                    //     },
                    //     {
                    //         name: 'Watchlist',
                    //         url: '/covenant-watchlist',
                    //         activeUrls: ['/covenant-watchlist'],
                    //     },
                    //     {
                    //         name: 'Shock Scenarios',
                    //         url: '/covenant-shock-scenarios',
                    //         activeUrls: ['/covenant-shock-scenarios'],
                    //     },
                    //     {
                    //         name: 'P&L',
                    //         url: '/covenant-pnl',
                    //         activeUrls: ['/covenant-pnl'],
                    //     },
                    // ]
                },
            ]
        },
        {
            name: 'ESG',
            url: '/ESG',
            activeUrls: ['/ESG'],
            icon: 'layer'
        },
        {
            name: 'Data',
            url: '/data',
            activeUrls: ['/data'],
            icon: 'data'
        },
        {
            name: 'User Assignment',
            url: '/user-assignment',
            activeUrls: ['/user-assignment'],
            icon: 'user-card'
        },

    ];
    routes;

    isGlobalFilterHidden = true;
    isUserAuthorized = false;

    constructor(
        private activatedRoute: ActivatedRoute,
        private title: Title,
        private userProfileService: UserProfileService,
        private authService: AuthService,
        private router: Router,
        private commonService: CommonService,
        private modalService: NgbModal,
        private userPermissionService: UserPermissionService,
        public location: Location,
        private utilityService: UtilityService) {
        this.authService.$authenticationState.subscribe(
            (isAuthenticated: boolean) => this.onAuthenticationStateChanged(isAuthenticated)
        );

        // this.setEmailGroup();
        this.handleRouteStartChanges();
        this.handleRouteEndChanges();
        this.routes = [
            ...this.startRoutes
        ].filter(r => {
            if (!environment.enableValuationApproval && ['/user-assignment'].includes(r.url)) { return false; }
            if (!environment.enableCovenant && ['/performance'].includes(r.url)) { return false; }
            return true;
        });
    }

    ngOnInit() { }

    async onAuthenticationStateChanged(isAuthenticated: boolean) {
        if (!isAuthenticated) {
            return;
        }

        this.authService.enableSessionTimeoutDialog();

        const { email, name } = await this.userProfileService.getLoggedInUser();

        this.checkUserAssignmentPageAccess();

        this.loggedInUserEmail = email;
        this.loggedInUserName = name;
    }

    private checkUserAssignmentPageAccess() {
        if (!this.userPermissionService.hasEditGroup()) {
            this.routes = this.routes.filter(i => i.url !== '/user-assignment');
        }
    }

    private toggleGlobalFilter(hidden: boolean = false) {
        this.isGlobalFilterHidden = hidden;

        if (hidden) {
            document.getElementsByClassName('wrap-contain')[0].classList.add('no-global-filter-layout');
        } else {
            document.getElementsByClassName('wrap-contain')[0].classList.remove('no-global-filter-layout');
        }
    }

    onLeftNavbarCollapsed(isCollapsed: boolean) {
        // this.leftNav.handleCollapseLeftBar()
        if (isCollapsed) {
            document.getElementsByClassName('header header-color common-layout')[0].classList.remove('expanded');
            document.getElementsByClassName('wrap-contain')[0].classList.add('collapsed-layout');
            document.getElementsByClassName('footer-bottom')[0].classList.add('collapsed-layout');
        } else {
            document.getElementsByClassName('header header-color common-layout')[0].classList.add('expanded');
            document.getElementsByClassName('wrap-contain')[0].classList.remove('collapsed-layout');
            document.getElementsByClassName('footer-bottom')[0].classList.remove('collapsed-layout');
        }
    }

    onClearCache() { }

    private checkUserAuthorized(url: string): void {
        this.isUserAuthorized = url !== '/401' && !!this.loggedInUserEmail;
    }

    private setPageTitle(): void {
        const title = ['READ'];
        let child = this.activatedRoute.firstChild;

        if (!child) {
            title.push(this.activatedRoute.snapshot.data.title);
        } else {
            while (child.firstChild) {
                child = child.firstChild;
            }

            if (child.snapshot.data.title) {
                title.push(child.snapshot.data.title);
            }
        }

        this.title.setTitle(title.join(' - '));
    }

    private checkGlobalFilterHidden(): void {
        let child = this.activatedRoute.firstChild;

        if (!child) {
            this.toggleGlobalFilter(this.activatedRoute.snapshot.data.hideGlobalFilter);
        } else {
            while (child.firstChild) {
                child = child.firstChild;
            }
            this.toggleGlobalFilter(child.snapshot.data.hideGlobalFilter);
        }
    }

    private handleRouteStartChanges() {
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart) {
                // Show loading indicator
                const href = window.location.href;
                if (href.indexOf('/index.html#') > -1) {
                    localStorage.setItem('OKTA_IMPLICIT_CALLBACK_HASH', window.location.hash);
                } else {
                    localStorage.removeItem('OKTA_IMPLICIT_CALLBACK_HASH');
                }
            }
        });

        this.utilityService.toggleLoading(false);
        let asyncLoadCount = 0;

        this.router.events.subscribe(
            (event: RouterEvent): void => {
                if (event instanceof RouteConfigLoadStart) {
                    asyncLoadCount++;
                } else if (event instanceof RouteConfigLoadEnd) {
                    asyncLoadCount--;
                }

                this.utilityService.toggleLoading(!!asyncLoadCount);
            }
        );
    }

    private handleRouteEndChanges() {
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                // check if user is authorized
                this.checkUserAuthorized(event.url);

                // set page title
                this.setPageTitle();

                // config global filter hidden or not
                this.checkGlobalFilterHidden();
            }
        });
    }

    showSettingModal({ type, isShow }) {
        switch (type) {
            case 'SETTING':
                this.showModal({ item: isShow, mode: ModalMode.EDIT });
                break;
            case 'KAR_ACCOUNT':
                this.showKarAccountModal({ item: isShow, mode: ModalMode.EDIT });
                break;
            default:
                break;
        }
    }

    private showModal({ item, mode }) {
        const modalOptions = this.utilityService.getModalOptions(this.modalClass, 'md');

        modalOptions.size = 'md' as any;
        this.modalRef = this.modalService.open(SettingModalComponent, modalOptions);

        if (this.modalRef) {
            this.modalRef.componentInstance.item = item;
            this.modalRef.componentInstance.mode = mode;
            this.modalRef.componentInstance.modalClass = this.modalClass;
        }
    }

    showKarAccountModal(item) {
        const modalOptions = this.utilityService.getModalOptions(this.modalClass, 'xl');

        modalOptions.size = 'xl' as any;
        this.modalRef = this.modalService.open(KarAccountComponent, modalOptions);

        if (this.modalRef) {
            this.modalRef.componentInstance.item = item;
            this.modalRef.componentInstance.mode = ModalMode.EDIT;
            this.modalRef.componentInstance.modalClass = this.modalClass;
        }
    }
}
