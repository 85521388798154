import { ReadNumeralService } from './services/read-numeral.service';
import { environment } from './../environments/environment';
import { UtilityService, KarLayoutComponentModule } from '@kayne/ka-ui-lib-ng7';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './routing.module';
import { AppComponent } from './app.component';
import { ComponentModule } from './components/component.module';
import { UrlSerializer } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { OktaAuthModule } from '@okta/okta-angular';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { ReadEndpointService } from './services/read-endpoint.service';
import { UserPermissionService } from './services/user-permission.service';
import {
  TokenInterceptor, HttpCacheInterceptor, ServerDownGuardService, KarPageComponentModule,
  UserProfileService,
  KarCoreComponentModule,
} from '@kayne/ka-ui-lib-ng7';
import {
  CustomUrlSerializer, IdleService, AuthGuardService,
  APP_CONFIG, APP_VERSION_CONFIG, AuthService, APP_AUTH_CONFIG
} from '@kayne/ka-ui-lib-ng7';

import packageJson from '../../package.json';
import { KarAccountModule } from './modules/kar-account/kar-account.module';
import { MainFilterModule } from './modules/main-filter/main-filter.module';
import { KarSharingModule } from './shared/sharing/kar-sharing.module';

const config = {
  issuer: environment.oktaIssuerURL,
  redirectUri: environment.oktaRedirectURL,
  clientId: environment.oktaClientId,
  autoRenew: true
};

const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 12
    },
    vertical: {
      position: 'top',
      distance: 12,
      gap: 10
    }
  }
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    ComponentModule,
    OktaAuthModule.initAuth(config),
    NotifierModule.withConfig(customNotifierOptions), // for toast
    // shared library
    KarCoreComponentModule,
    KarLayoutComponentModule,
    KarPageComponentModule,
    KarAccountModule,
    // main filter
    MainFilterModule,
    KarSharingModule.forRoot(),
  ],
  providers: [
    HttpClientModule,
    {
      provide: UrlSerializer,
      useClass: CustomUrlSerializer
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCacheInterceptor,
      multi: true
    },
    {
      provide: APP_CONFIG,
      useValue: environment
    },
    {
      provide: APP_VERSION_CONFIG,
      useValue: packageJson
    },
    {
      provide: APP_AUTH_CONFIG,
      useValue: config
    },
    UtilityService,
    ReadNumeralService,
    UserProfileService,
    ServerDownGuardService,
    AuthService,
    AuthGuardService,
    IdleService,
    ReadEndpointService,
    UserPermissionService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
