import { Input, Output, EventEmitter, HostListener, ViewChild } from '@angular/core';
import { Component } from '@angular/core';
import { OnInit } from '@angular/core';
import { FadeInOut } from '@kayne/ka-ui-lib-ng7';

@Component({
    selector: 'app-select-dropdown',
    templateUrl: './select-dropdown.component.html',
    styleUrls: ['./select-dropdown.component.css'],
    animations: [FadeInOut]
})
export class SelectDropdownComponent implements OnInit {
    constructor() {
        this.data = [];
    }
    /** value of the dropdown */
    @Input() data: any;
    @Input() isHideText = false;
    @Output() selectChange = new EventEmitter<any>();
    isShow = false;

    @ViewChild('list') element;

    @HostListener('document:click', ['$event']) clickedOutside($event) {
        // here you can hide your menu
        const el = $event.target.closest('.list-select');
        if (!el) {
            this.isShow = false;
        }
    }


    ngOnInit() {
    }

    togglePanel($event: Event) {
        $event.preventDefault();
        $event.stopPropagation();
        this.isShow = !this.isShow;
    }

    handleOnChange() {
        this.selectChange.emit(this.data);
    }
}
